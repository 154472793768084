'use client';

import { Flex } from '@chakra-ui/react';
import { Poppins } from 'next/font/google';
import type { ReactNode } from 'react';

const poppins = Poppins({
  weight: ['300', '400', '500', '600', '700'],
  subsets: ['latin'],
});

interface props {
  children: ReactNode;
}

export default function LayoutClient({ children }: props) {
  return (
    <Flex
      h='100vh'
      w='100vw'
      justifyContent='start'
      alignItems='center'
      flexWrap='nowrap'
      fontFamily={poppins.style.fontFamily}
    >
      {children}
    </Flex>
  );
}
